.app-header {
    width: 80%;
    text-align: center;
    margin: auto;
}

.nav-bar {
    width: 100%;
    margin: auto;
}

.nav-bar a {
    padding-right: 15px;
}

.photo-board {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-left: 20%;
    margin-right: 20%;
}

.photo-collage {
    width: 50%;
    margin: auto;
}

.photo-collage-title {
    width: 80%;
    margin: auto;
    text-align: center;
}

.photo-row {
    width: 90%;
    margin: auto;
    padding: 3px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.photo-cell {
    width: 30%;
    height: 80px;
    padding: 3px;
    margin: auto;
    border: 1px solid #333333;
}

@media only screen and (max-width: 864px) {
    /* For mobile phones: */
    .photo-board {
        margin-left: 0%;
        margin-right: 0%;
    }
    .photo-collage {
        width: 100%;
        margin: auto;
    }
}